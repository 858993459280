
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: 'BaseAppbar',
  props: {
    title: { type: String, default: '' }
  },
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    return { goBack };
  },
});
