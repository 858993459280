
import BaseAppbar from "@/components/UI/BaseAppbar.vue";
import DropDown from "@/components/Dropdown.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { computed, defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { createToast } from "mosha-vue-toastify";

type State = {
  driverLicenseNumber: string;
  photo1: null | File;
  photo2: null | File;
  carBrand: string;
  carType: string;
  carNumber: string;
};

export default defineComponent({
  components: { BaseAppbar, DropDown },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const formIsValid = ref(true);
    const isLoading = ref(false);
    const noPhoto = reactive({
      photo1: false,
      photo2: false,
    });
    const state = reactive<State>({
      driverLicenseNumber: "",
      photo1: null,
      photo2: null,
      carBrand: "i0",
      carType: "car",
      carNumber: "",
    });
    const rules = computed(() => ({
      driverLicenseNumber: {
        required: helpers.withMessage(t("required"), required),
      },
      carNumber: { required: helpers.withMessage(t("required"), required) },
    }));

    const v$ = useVuelidate(rules, state, { $autoDirty: true });

    const onFile = (key: "photo1" | "photo2", file: File | null) => {
      if (!file) {
        noPhoto[key] = true;
        formIsValid.value = false;
      } else {
        state[key] = file;
        noPhoto[key] = false;
        formIsValid.value = true;
      }
    };

    const carBrands = store.getters.marka;

    const options = [
      { id: "car", name: t("carOptions.car") },
      { id: "bus", name: t("carOptions.bus") },
      { id: "truck", name: t("carOptions.truck") },
      { id: "delivery", name: t("carOptions.delivery") },
    ];

    const verify = async () => {
      onFile("photo1", state.photo1);
      onFile("photo2", state.photo2);
      const isValid = await v$.value.$validate();
      if (!isValid) formIsValid.value = false;
      if (!formIsValid.value) return;

      const data = {
        status: "pending",
        licenseNumber: state.driverLicenseNumber,
        licenseFrontPhoto: null,
        licenseBackPhoto: null,
        carNumber: state.carNumber,
        carBrand: state.carBrand,
        carType: state.carType,
      };

      router.replace("/profile?status=pending");
      createToast(t("requestSent"), {
        type: "info",
        hideProgressBar: true,
        position: "top-center",
        showIcon: true,
      });

      isLoading.value = true;
      try {
        const u1 = await store.dispatch("upload", { file: state.photo1 });
        isLoading.value = false;
        const u2 = await store.dispatch("upload", { file: state.photo2 });
        data.licenseFrontPhoto = u1;
        data.licenseBackPhoto = u2;
        await store.dispatch("updateUserDoc", data);
      } catch (err: any) {
        console.log(err.message);
      }
    };

    return {
      t,
      state,
      v$,
      verify,
      formIsValid,
      noPhoto,
      onFile,
      options,
      carBrands,
      isLoading,
    };
  },
});
