import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6215c90b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "placeholder"
}
const _hoisted_4 = {
  key: 1,
  class: "selected-value"
}
const _hoisted_5 = { class: "chevrons" }
const _hoisted_6 = { class: "options" }
const _hoisted_7 = ["for"]
const _hoisted_8 = ["id", "value", "onChange", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_icon = _resolveComponent("f-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: `dropdown_${_ctx.name}`
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(['dropdown', { open: _ctx.open }]),
      id: `dropdown_${_ctx.name}`,
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      (!_ctx.isSelected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.placeholder), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.selectedName), 1)),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_f_icon, {
          icon: "chevron-down",
          rotation: "180"
        }),
        _createVNode(_component_f_icon, { icon: "chevron-down" })
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            key: option.id,
            class: _normalizeClass(['option', { selected: option.name === _ctx.selectedName }])
          }, [
            _renderSlot(_ctx.$slots, "icon", { option: option }, () => [
              _withDirectives(_createElementVNode("span", null, _toDisplayString(option[_ctx.label]), 513), [
                [_vShow, option[_ctx.label]]
              ])
            ], true),
            _createElementVNode("label", {
              class: _normalizeClass({ w: _ctx.name === 'brands' }),
              for: option.id,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = false))
            }, _toDisplayString(option.name), 11, _hoisted_7),
            _createElementVNode("input", {
              id: option.id,
              type: "radio",
              value: option.id,
              onChange: ($event: any) => (_ctx.changed(option.id, option.name)),
              name: _ctx.name
            }, null, 40, _hoisted_8)
          ], 2))
        }), 128))
      ])
    ], 10, _hoisted_2)
  ]))
}