
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "TheDropdown",
  props: {
    label: { type: String, default: "" },
    options: { type: Array, default: () => [] },
    localeKey: { type: String, default: "carOptions" },
    placeholder: { type: String, default: "" },
    modelValue: { type: String, default: "" },
    selected: { type: String, default: "" },
    name: { type: String, default: "car-type" },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const open = ref(false);
    const selected = ref(props.selected);
    const isSelected = computed(() => selected.value !== "");
    const selectedName = ref("");

    const setSelectedNameLocale = () =>
      (selectedName.value = t(`${props.localeKey}.${props.selected}`));

    // This check runs once when component is creating
    if (isSelected.value && props.selected !== "") setSelectedNameLocale();

    watch(locale, () => setSelectedNameLocale());

    const setDataName = (name: string) => (selectedName.value = name);

    const toggle = () => (open.value = !open.value);

    const changed = (value: string, name: string) => {
      selected.value = value;
      setDataName(name);
      emit("update:modelValue", value);
    };

    return {
      open,
      toggle,
      changed,
      isSelected,
      selectedName,
    };
  },
});
