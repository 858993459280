import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb1c7346"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseAppbar = _resolveComponent("BaseAppbar")!
  const _component_base_loading_page = _resolveComponent("base-loading-page")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_file_input = _resolveComponent("base-file-input")!
  const _component_drop_down = _resolveComponent("drop-down")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseAppbar, {
      title: _ctx.t('verify.title')
    }, null, 8, ["title"]),
    _createVNode(_component_base_loading_page, { show: _ctx.isLoading }, null, 8, ["show"]),
    _createElementVNode("form", {
      onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.verify && _ctx.verify(...args)), ["prevent"]))
    }, [
      _createVNode(_component_base_input, {
        label: _ctx.t('verify.number'),
        modelValue: _ctx.state.driverLicenseNumber,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.driverLicenseNumber) = $event)),
        invalid: _ctx.v$.driverLicenseNumber.$error,
        message: _ctx.v$.driverLicenseNumber,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.formIsValid = true))
      }, null, 8, ["label", "modelValue", "invalid", "message"]),
      _createVNode(_component_base_file_input, {
        label: _ctx.t('verify.front'),
        onFile: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFile('photo1', $event))),
        invalid: _ctx.noPhoto.photo1
      }, null, 8, ["label", "invalid"]),
      _createVNode(_component_base_file_input, {
        label: _ctx.t('verify.back'),
        onFile: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFile('photo2', $event))),
        invalid: _ctx.noPhoto.photo2
      }, null, 8, ["label", "invalid"]),
      _createVNode(_component_drop_down, {
        options: _ctx.carBrands,
        name: "brands",
        "locale-key": "carBrands",
        modelValue: _ctx.state.carBrand,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.carBrand) = $event)),
        label: _ctx.t('verify.carBrand'),
        selected: _ctx.state.carBrand
      }, null, 8, ["options", "modelValue", "label", "selected"]),
      _createVNode(_component_drop_down, {
        options: _ctx.options,
        modelValue: _ctx.state.carType,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.carType) = $event)),
        label: _ctx.t('verify.carType'),
        selected: _ctx.state.carType
      }, {
        icon: _withCtx(({ option }) => [
          _createElementVNode("img", {
            class: "option-icon",
            src: `/icons/${option.id}.png`,
            alt: option.id
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["options", "modelValue", "label", "selected"]),
      _createVNode(_component_base_input, {
        label: _ctx.t('verify.carNumber'),
        modelValue: _ctx.state.carNumber,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.carNumber) = $event)),
        invalid: _ctx.v$.carNumber.$error,
        message: _ctx.v$.carNumber,
        onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.formIsValid = true))
      }, null, 8, ["label", "modelValue", "invalid", "message"]),
      _createVNode(_component_base_button, {
        mode: "orange",
        disabled: !_ctx.formIsValid
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("verify.title")), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 32)
  ], 64))
}